.all__tree {
    height: 100%;
    overflow: auto;
}

.org-tree ul {
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.org-tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    margin-top: 20px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.org-tree li::before,
.org-tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
}
.org-tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
    display: none;
}

.org-tree li:only-child {
    padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
    border: 0 none;
}

.org-tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}

.org-tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
}

.org-tree li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;

    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.org-tree li a:hover,
.org-tree li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
}

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before {
    border-color: #94a0b4;
}

.card1 {
    position: relative;
    padding-top: 50px;
    /* width: 300px; */
}

.card1 .image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
    z-index: 100;
}
.card1 .image img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    border: 13px solid #96f6b7;
    position: relative;
}
.card1 .card1-body {
    background: rgb(247, 243, 243);
    height: 51.2px;
    width: 112px;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 0 0 0;
}

.card1 .card1-body h4 {
    font-weight: 600;
    line-height: 20px;
    font-size: small;
}
.card1 .card1-body p {
    line-height: 20px;
    font-size: small;
}
.card1 .card1-footer {
    padding: 5px 3px;
    text-align: center;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    width: 112px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 100;
    left: 50%;
    color: white;
    transform: translateX(-50%);
}
.card1 .card1-footer img {
    width: 25px;
    margin: 5px;
}

.org-tree {
    margin: 16px;
}
