.row {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
}

img {
    width: 60%;
    height: min-content;
}

h5 {
    width: fit-content;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.column {
    /* float: left; */
    width: 33.3%;
    margin-bottom: 16px;
    padding: 0 8px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
}

.container {
    padding: 0 16px;
}

.container::after,
.row::after {
    content: "";
    clear: both;
    display: table;
}

@media screen and (max-width: 650px) {
    .column {
        width: 100%;
        display: block;
    }
}
