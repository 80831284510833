.core {
    padding: 20px;
}
#testt {
    height: 200px;
    width: 200px;
}
.tabs_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tabs_box_item {
    margin-top: 10px;
    margin-left: 10px;
    align-self: flex-start;
    cursor: pointer;
}
@media only screen and (max-width: 800px) {
    .core {
        padding: 0px;
    }
    #testt {
        height: 100px;
        width: 100px;
    }
}
.css-19kzrtu {
    padding: 0 !important;
}
#testt_img {
    width: 312px;
}

@media only screen and (max-width: 506px) {
    .res {
        flex-direction: column;
    }
}
@media only screen and (max-width: 1100px) {
    .list_item {
        min-width: 800px;
    }
    .core_list {
        overflow-x: auto;
    }
}
.core_icon {
    margin-right: 10px !important;
}

.company {
    padding: 20px;
}
.addcompany {
    display: flex;
    justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
    .addcompanymodal {
        width: 90% !important;
    }
    .moreInfoModal {
        width: 90% !important;
    }
}
.right_btn {
    margin-top: 20px;
    text-align: right;
}
.moreInfo_wrapper {
    display: flex;
    justify-content: baseline;
}
.moreInfo {
    font-weight: bold;
}
