.row {
    justify-content: space-between !important;
}

.skill-levels {
    color: #aaa;
}

.skill-board {
    border: 1px solid #ddd;
    padding: 0.5rem !important;
    border-radius: 4px;
    box-shadow: 0 4px 4px -2px hsla(0, 0%, 55%, 0.45), 0 0 1px 1px hsla(0, 0%, 40%, 0.48);
}

.skill-board span {
    cursor: pointer;
    font-size: 13pt;
    height: 33px;
    line-height: 30px;
    padding: 0 12px;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 0.375rem !important;
}
