.crds {
    padding-left: 0;
    padding-right: 0;
}

.dshbrd {
    width: 92%;
    margin: 32px auto auto;
}

.JaG {
    margin-bottom: 24px;
}

@media screen and (max-width: 400px) {
    .dshbrd {
        margin: 0;
    }
}
