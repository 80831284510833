.wc-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
    padding: 30px 60px;
    text-transform: uppercase;
    font-weight: 500;
    /* color: #acb9b9; */
    /* color: #d0dfdf; */
    /* color: #7a8181; */
    /* color: #626e6e; */
    color: #455252;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@media only screen and (max-width: 1500px) {
    .wc-container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width: 1200px) {
    .wc-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 900px) {
    .wc-container {
        grid-template-columns: repeat(1, 1fr);
        width: 400px;
        margin-inline: auto;
    }
}

.wcc-month {
    height: 200px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: clamp(280px, 100%, 320px);
    margin-inline: auto;
}

.wcc-month > * {
    text-align: center;
}

.wccm-week {
    font-size: 0.9em;
    opacity: 0.8;
}
.today {
    position: relative;
}
.today::after {
    content: " ";
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    margin-top: 1px;
    border: 2px solid seagreen;
}

.day-off {
    color: #ec3c3c;
}
.day-other {
    opacity: 0.35;
}

.day {
    display: flex;
    justify-content: center;
    align-items: center;
}
