.create-timeoff {
    text-align: right;
    margin: 2em 10em 0 2em;
}

.time-off-screen .css-1qapnz0 {
    margin-top: 0 !important;
}

.w-50 {
    width: fit-content !important;
}

.box-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    min-width: 500px;
    max-width: 700px;
    background-color: white;
    /* border: "2px solid #000", */
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

.box-style-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    background-color: white;
    /* border: "2px solid #000", */
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}

@media screen and (max-width: 600px) {
    .box-style {
        width: 100%;
        min-width: auto;
    }
}

.apply {
    margin-left: 40px !important;
    margin-bottom: 40px !important;
    margin-top: 0 !important;
}

.box-style .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.css-1udlkje-MuiPaper-root {
    width: 100%;
    height: 100%;
    margin: 0;
}

.overlay {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    cursor: wait;
}

.css-1onucoh-JoyTextarea-textarea {
    overflow: hidden;
}

.time-off-screen .MuiTableCell-root.MuiTableCell-body.css-1yhpg23-MuiTableCell-root {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
