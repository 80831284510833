.company {
    padding: 20px;
}
.addcompany {
    display: flex;
    justify-content: flex-end;
}
.left_right {
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 600px) {
    .addcompanymodal {
        width: 90% !important;
    }
    .moreInfoModal {
        width: 90% !important;
    }
}
