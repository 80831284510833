.create-recognition {
    display: flex !important;
    justify-content: flex-end;
}

@media screen and (max-width: 600px) {
    .create-recognition {
        display: block !important;
        margin: 24px auto;
    }
}

.MuiList-root li {
    float: none !important;
}

ul.MuiList-root {
    background-color: inherit;
}

.cvs img {
    width: 32px;
    border-radius: 50%;
}

.cvs .activecv {
    background-color: #bfe4eb;
}
.badge-name {
    font-weight: 600;
    color: #bb6600;
}
.r-con-flb {
    display: flex;
}

@media screen and (max-width: 576px) {
    .r-con-flb {
        display: inline-block !important;
    }
}

.rb-select {
    width: fit-content;
    max-width: 360px;
    width: 100%;
    margin-right: 30px;
}

.rb-items {
    height: fit-content;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.rbi-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* align-items: stretch; */
    width: 12em;
    height: 12em;
    background-color: rgba(234, 220, 223, 0.6);
    /* background-color: #f0eded; */
    border: 2px #e4ad6e;
    border-style: double;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    cursor: default;
    /* float: left;
     */
}

.rbii-head {
    /* flex-grow: 1; */
    width: 100%;
    display: flex;
    justify-content: baseline;
    align-items: center;
    /* background-color: red; */
}

.rbi-item img {
    border: 2px #e49170;
    border-style: double;
    margin: 5px;
    border-radius: 50%;
    width: 3em;
    height: 3em;
}

.rbii-body {
    height: 50%;
}

.rbiib-comment {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 90%;
    /* white-space: nowrap */
    font-size: 0.9rem;
}

.rbiibc-from {
    font-weight: 600;
}
