.page .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    display: flex;
    align-items: baseline;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #fff;
}

.spinner21 {
    width: 100%;
    padding-top: 40px;
    display: flex;
    justify-content: center;
}

.MuiTableCell-root button {
    float: none !important;
}

.time-off-history .MuiTableCell-root.MuiTableCell-body.css-1yhpg23-MuiTableCell-root {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
