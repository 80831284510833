.input_div {
    position: relative;
}

.input_div svg {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}

.input_div input {
    padding-right: 44px;
}
.welcome {
    height: 90vh;
    overflow-y: auto !important;
}
.w_title {
    display: block;
    flex-direction: column;
    justify-content: center;
    padding-left: 70px;
    margin: 40px 0 70px;
}
.w_title h1 {
    font-size: 5.1vw;
    font-weight: bold;
    color: rgb(255, 149, 0);
    color: #4a7c6b;
}
.w_title p {
    font-size: 24pt;
    font-style: italic;
    color: #000;
}
.w_bottom {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}
.welcome a {
    text-decoration: none;
}
#account-menu li,
#menu-type li {
    float: none !important;
}
.css-duaqfz .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;
}

.MuiTablePagination-displayedRows css-1chpzqh,
p {
    margin-bottom: 0rem !important;
}
.right_left {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .Popup {
        overflow-y: scroll;
        width: 90% !important;
        height: fit-content !important;
    }
}
.marginBottom {
    margin-bottom: 10px !important;
}
.MuiBox-root css-1mb7ed4 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.css-dxsb9 {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

@media screen and (max-width: 415px) {
    .chng {
        flex-direction: column !important;
    }
    .chng button {
        margin-left: 0 !important;
    }
}
.MuiTableCell-root MuiTableCell-head MuiTableCell-alignLeft MuiTableCell-sizeMedium css-1bigob2 {
    font-weight: 700 !important;
}
.css-1bigob2 {
    font-weight: 700 !important;
}
.css-1ygcj2i-MuiTableCell-root {
    font-weight: 700 !important;
}

#body_spin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 82vh;
}

.center_center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.center_between {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.err_comment {
    font-size: 12px;
    margin: 3px 14px 0;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    color: #d32f2f;
}

@media screen and (max-width: 800px) {
    .overflow_scroll {
        overflow-y: auto !important;
        height: 80vh !important;
        width: 80% !important;
    }
    .welcome .col-md-4 {
        padding: 0 8px;
    }
}
@media screen and (max-height: 1050px) {
    .overflow_scroll {
        overflow-y: auto !important;
        height: 80vh !important;
        width: 80% !important;
    }
}
.right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.hrp-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20000000;
    background-color: transparent;
    cursor: wait !important;
}

@media screen and (min-width: 1050px) {
    .welcome .col-md-4 {
        padding: 0;
    }
}
@media screen and (max-width: 1100px) {
    .w_title {
        width: 100%;
        float: start;
        padding: 20px 0 0 0;
        text-align: center;
        justify-content: flex-start;
        margin: 25px 0;
    }
    .w_title h1 {
        font-size: 30pt;
    }
    .w_title p {
        font-size: 18pt;
    }
}
@media screen and (max-width: 700px) {
    .w_title {
        margin: 10px 0;
    }
    .w_title h1 {
        font-size: 22pt !important;
    }
    .w_title p {
        font-size: 13pt !important;
    }
}
