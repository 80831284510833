ul.optionContainer {
    background-color: #fff;
}

.optionContainer li {
    float: none !important;
}

.recg-label {
    display: flex !important;
    justify-content: flex-end;
    padding-top: 0.5em !important;
    height: 100%;
    font-weight: 600 !important;
}

@media screen and (max-width: 600px) {
    .recg-label {
        justify-content: flex-start;
    }
}

.badges {
    /* background-color: red; */
    width: fit-content;
    max-width: 100%;
    min-height: 120px;
    height: fit-content;
    border: 1px solid #d8d8df;
    border-radius: 8px;
}

.badges img {
    cursor: pointer;
    width: 100px;
    margin: 10px;
    padding: 5px;
    border: 1px solid #d8d8df;
    border-radius: 8px;
    transition: 0.15s;
}

.badges img.active {
    border: 4px solid #eedea8;
    /* #e9c43f */
    box-shadow: 5px 5px 5px #f1e5bc, 5px -5px 5px #f1e5bc, -5px -5px 5px #f1e5bc, -5px 5px 5px #f1e5bc;
    border-radius: 8px;
    background-color: #fff;
}
