body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.header {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid rgb(240, 240, 240, 0.5);
}
.header a.active {
    background-color: rgb(230, 232, 255) !important;
}
.header li a:hover {
    background-color: rgb(230, 232, 255) !important;
    color: black;
}
.header .dropdown-toggle::after {
    display: none;
}
.header li a {
    padding-left: 15px !important;
}

#head_content {
    height: 88vh;
    width: 100%;
}

.table21 {
    height: 35vh !important;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}

.forms {
    margin-left: 40%;
    align-items: center;
}

.form-time-off {
    width: 20%;
}
.m_icons {
    font-size: 17px !important;
}

@media screen and (max-width: 1200px) and (min-width: 770px) {
    .donut {
        padding: 3vw;
    }
}

@media screen and (max-width: 400px) {
    #head_content {
        padding: 16px 0px;
    }

    .Dashboard h4 {
        font-size: 18px;
    }
    .apexcharts-legend-text {
        font-size: 8px !important;
    }

    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
        width: 50vw !important;
        padding: 0 !important;
    }
}

.file_md {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    width: 100%;
    height: 100%;
}
